.card-container {
    display: flex;
    flex-wrap: wrap;
    margin: 8px;
    font-family: Poppins !important;
  }
.card-front{
    width: 306px;
  margin: 10px;
    height: 410px;
    border-radius: 13px !important;
    cursor: pointer;
    background-color: #eaf3f0;
}

.card-back{
    background-color: #eaf3f0;
}
.flipped-card {
    box-shadow: 0px 0px 20px #232b2833;
    cursor: pointer;
}
.main-shimmer{
  margin-top: 1rem;
}

.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}

.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.end {
    width: 40%;
  }

  @media only screen and (max-width: 375px) {
   .card-front{
    width: 245px;
    margin: 0;
    margin-top: 40px;
   }
  }

