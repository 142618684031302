.sidebar-menu {
  background-color: #065036;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sidebar-menu:hover {
  background-color: #0b724e !important;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sidebar-option {
  color: #ffffff;
  font-size: 10px !important;
}

.sidebar-benefit {
  color: #ffffff;
  font-size: 10px !important;
}

.selected-item {
  background-color: #0b724e !important;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px !important;
}

@media only screen and (max-width: 900px) {
  .sidebar-image {
    display: none;
  }
}
