/* src/style.css */
.App-header {
    position: relative;
  }
  
  .nablogo1 {
    position:fixed;
    top: 25px;
    width: 14.18%;
    left: 28px;
  }
  
  .vec2 {
    position: fixed;
    bottom: 0;
    width:13%;
    left: 0;
  }
  
  .vec3 {
    top: 0%;
    position: fixed;
    width: 41%;
    right: 0;
  }
  
  .Create-text {
    font-size: 40px; 
    position: relative;
    color: #000; 
    height: 79px;
    top: 70px;
    width:340px;
    line-height: normal;
    letter-spacing: 3.6px;
    margin-left: 22% ;
    font-weight: 400;
}

.logo-header-two {
    font-size: 15px; 
    position: relative;
    color: #797979; 
    top: 20px;
    width:241px;
    height: 15px;
    margin-left: 26% ;
    font-weight: 400;
    line-height: normal;
}

.text-fields {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 21%;
}

.text-fields input {
  width: 350px;
  height: 55px;
  border: none;
  border-radius: 5px;
  margin-bottom: 18px;
  padding: 20px;
  background: #D0EECA;
  box-sizing: border-box;
}

.signup-button {
  width: 378px;
  height: 57px;
  flex-shrink: 0;
  border: none;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20%;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.35);
}

.alternate
{
  color: #797979;;
  margin-left: 26%;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 220px;
  height: 22px;
}