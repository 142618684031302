@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
    url(../../Assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "opensans-regular";
    src: local("opensans-regular"),
    url(../../Assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "opensans-semibold";
    src: local("opensans-semibold"),
    url(../../Assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}

.background-image-container {
    margin: 32px;
    border-radius: 5px;
    background-image: url('../../Assets/Rectangle\ 6.svg');
}
.search-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-left: 0.6cm;
}
.grid-container {
    transition: all 0.5s ease;
    margin-left: 1.5%;
}

.grid-container.expanded {
    cursor: pointer;
    /* Optional: Change cursor on expansion */
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.card {
    border: 1px solid #ccc;
    padding: 10px;
    transition: transform 0.3s ease;
    width: 270px !important;
    height: 210px !important;
    border-radius: 6px;
    background-color: rgba(62, 103, 63, 0.91) !important;
    background-size: cover;
    background-position: center;
}

.card.card-expanded {
    height: 460px !important;
    /* width: 500px; */
}

.card:hover {
    transform: scale(1.05);
}

.extra-content {
    padding: 10px;
}

.extra-info {
    display: flex;
    justify-content: space-between;
}

.left-content {
    flex-grow: 1;

}

.statename {
    color: #FFF;
    font-family: "opensans-regular";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 200px;
}

.right-content {
    text-align: right;
}

.trends {
    font-family: "Open Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.active {
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.72);
    width: 50px;
    font-size: 9px;
    height: 20px;
    text-align: center;
    justify-content: center;
    padding: 5px;
}

.numbers {
    color: #FFF;
    font-family: "opensans-semibold";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
}

.description-text {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    /* margin-bottom: 10px; */
}

.see-detailed-report {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.top-5-view {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.first-div-box {
    width: 35px;
    height: 258.982px;
    transform: rotate(90deg);
    margin-left: 2.5cm;
    background: #337377;
    margin-top: -100px;
    display: flex;
    align-items: start;
    border-top: 2px solid #D8BE39;
}


.div-one {
    color: rgba(255, 255, 255, 0.90);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: rotate(270deg);
    width: 87px;
    white-space: nowrap;
}

.second-div-box {
    margin-top: 10px;
    width: 35px;
    height: 258.982px;
    transform: rotate(90deg);
    margin-left: 2.5cm;
    background: #337377;
    margin-top: -100px;
    display: flex;
    align-items: start;
    border-top: 2px solid #D8BE39;
}

.top-5img {
    margin-left: 8px;
}

.right-align {
    text-align: right;
}


/* ====================for first graph */
.custom-chart-width {
    width: 500px;
    /* margin-left: -30px; */
}

/* .custom-chart-width-second{
    width: 300px;
    margin-left: -30px;
} */




/* Add responsive styles as needed
  @media (max-width: 768px) {
    .extra-info {
      flex-direction: column;
    }
  }
   */

.dialog {
    width: 537px;
    height: 266px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #C6E4D6;
}

.custom-table {
    margin: auto;
    margin-top: 20px;
    width: 500px;
    border-collapse: collapse;
}

.custom-table tbody tr:hover{
    border-radius: 6px !important;
    background: #309A34;
    color: white;
    }

/* .custom-table thead {
    border-radius: 7px !important;
    background: #1D4131;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-family: Roboto !important;
    height: 37px;
    font-size: 12px;
} */
.custom-table-thead{
    border-radius: 7px !important;
    background: #1D4131;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-family: Roboto !important;
    height: 37px;
    font-size: 12px;
}

.custom-table thead  th{
    text-align: center;
}

/* .custom-table tbody {
    color: black;
    text-align: center;
    font-family: Roboto !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 35px;

} */
.custom-table-tbody{
    color: black;
    text-align: center;
    font-family: Roboto !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 35px;
}
/* .custom-table tbody tr{
    width: 35px;
height: 20px
} */

.custom-table-tbody-tr{
    width: 35px;
height: 30px
}
.img{
    margin-right: 4%;
    /* margin-top: 1%; */
    cursor: pointer;
}

.header {
    margin-left: 3%;
    color: #595959;
    font-family: "Open Sans" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
    url(../../Assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "opensans-regular";
    src: local("opensans-regular"),
    url(../../Assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "opensans-semibold";
    src: local("opensans-semibold"),
    url(../../Assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}

.background-image-container {
    margin: 32px;
    border-radius: 5px;
    background-image: url('../../Assets/Rectangle\ 6.svg');
}
.search-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-left: 0.6cm;
}
.grid-container {
    transition: all 0.5s ease;
    margin-left: 2.2%;
}

.grid-container.expanded {
    cursor: pointer;
    /* Optional: Change cursor on expansion */
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.card {
    border: 1px solid #ccc;
    padding: 10px;
    transition: transform 0.3s ease;
    width: 255px !important;
    height: 210px !important;
    border-radius: 6px;
    background-color: rgba(62, 103, 63, 0.91) !important;
    background-size: cover;
    background-position: center;
}

.card.card-expanded {
    height: 460px !important;
    /* width: 500px; */
}

.card:hover {
    transform: scale(1.05);
}

.extra-content {
    padding: 10px;
}

.extra-info {
    display: flex;
    justify-content: space-between;
}

.left-content {
    flex-grow: 1;

}

.statename {
    color: #FFF;
    font-family: "opensans-regular";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 200px;
}

.right-content {
    text-align: right;
}

.trends {
    font-family: "Open Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.active {
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.72);
    width: 50px;
    font-size: 9px;
    height: 20px;
    text-align: center;
    justify-content: center;
    padding: 5px;
}

.numbers {
    color: #FFF;
    font-family: "opensans-semibold";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
}

.description-text {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 10px;
}

.see-detailed-report {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.top-5-view {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.first-div-box {
    width: 35px;
    height: 258.982px;
    transform: rotate(90deg);
    margin-left: 2.5cm;
    background: #337377;
    margin-top: -100px;
    display: flex;
    align-items: start;
    border-top: 2px solid #D8BE39;
}


.div-one {
    color: rgba(255, 255, 255, 0.90);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: rotate(270deg);
    width: 87px;
    white-space: nowrap;
}

.second-div-box {
    margin-top: 10px;
    width: 35px;
    height: 258.982px;
    transform: rotate(90deg);
    margin-left: 2.5cm;
    background: #337377;
    margin-top: -100px;
    display: flex;
    align-items: start;
    border-top: 2px solid #D8BE39;
}

.top-5img {
    margin-left: 8px;
}

.right-align {
    text-align: right;
}


/* ====================for first graph */
.custom-chart-width {
    width: 400px;
    /* margin-left: -30px; */
}

/* .custom-chart-width-second{
    width: 300px;
    margin-left: -30px;
} */




/* Add responsive styles as needed
  @media (max-width: 768px) {
    .extra-info {
      flex-direction: column;
    }
  }
   */

.dialog {
    width: 537px;
    height: 266px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #C6E4D6;
}

.custom-table {
    margin: auto;
    margin-top: 20px;
    width: 500px;
    border-collapse: collapse;
}

.custom-table tbody tr:hover{
    border-radius: 6px !important;
    background: #309A34;
    color: white;
    }

/* .custom-table thead {
    border-radius: 7px !important;
    background: #1D4131;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-family: Roboto !important;
    height: 37px;
    font-size: 12px;
} */
.custom-table-thead{
    border-radius: 7px !important;
    background: #1D4131;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-family: Roboto !important;
    height: 37px;
    font-size: 12px;
}

.custom-table thead  th{
    text-align: center;
}

/* .custom-table tbody {
    color: black;
    text-align: center;
    font-family: Roboto !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 35px;

} */
.custom-table-tbody{
    color: black;
    text-align: center;
    font-family: Roboto !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 35px;
}
/* .custom-table tbody tr{
    width: 35px;
height: 20px
} */

.custom-table-tbody-tr{
    width: 35px;
height: 30px
}
.img{
    margin-right: 4%;
    /* margin-top: 1%; */
    cursor: pointer;
}

.header {
    margin-left: 3%;
    color: #595959;
    font-family: "Open Sans" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}