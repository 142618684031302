@font-face {
  font-family: "Open Sans";
  src: url("../../Assets/fonts/OpenSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.mainDiv {
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
}

.greBox {
  color: white;
  background-color: #065036;
  padding-bottom: 70px;
}

.FootDiv {
  color: white;
  background-color: #083c2a;
}

.topDiv {
  background-color: #eef4ed;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 50px;
  padding-bottom: 20px;
  z-index: 1;
  position: relative;
}

.imgCont {
  background-color: white;
  margin-bottom: -10px;
}

.greBox-2 {
  color: white;
  width: 100%;
  background-color: #065036;
}

.flipping-box {
  width: 58px;
  height: 86px;
  border-radius: 1px;
  background: #ececec;
  border: 1px solid #7fb800;
  position: relative;
}

.flipping-box .flipper {
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  position: relative;
}

.flipping-box.flip .flipper {
  transform: rotateY(180deg);
}

.podiumImg {
  width: 50%;
  padding-top: 60px;
  padding-right: 30px;
}

.profileImgStackH {
  margin-left: 20px !important;
}

@media only screen and (max-width: 900px) {
  .profileImgStackH {
    display: none !important;
  }

  .greBox {
    padding-left: 26px !important;
    padding-right: 26px !important;
  }

  .profileImg2StackH {
    display: block !important;
  }

  .topCont {
    padding: 0px;
  }

  .topDiv {
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .podiumImg {
    width: 90%;
    padding-top: 60px;
    padding-right: 0px;
  }

  .img1 {
    width: 100px !important;
  }
}

@media only screen and (max-width: 430px) {
  .textMainH {
    font-size: 1rem !important;
    text-align: center !important;
  }

  .greBox {
    padding: 5% !important;
  }

  .stack1 {
    align-items: normal !important;
  }

  .textBodyH {
    /* text-align: left !important; */
    font-size: 0.8rem !important;
    line-height: 1.5rem !important;
    width: auto !important;
  }

  .pacsDivH {
    margin-left: 1rem !important;
  }
}

@media only screen and (max-width: 464px) {
  .stack2H {
    flex-direction: column !important;
  }

  .stack3H {
    margin-left: 0px !important;
    padding-top: 10px;
  }

  .imageStackH {
    height: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .img1H {
    transform: translateY(20%) !important;
  }

  .topStateDivH {
    flex-direction: column !important;
    align-items: center !important;
  }

  .stackTop1h {
    margin-left: 0 !important;
  }

  .stackTop2h {
    margin-left: 0 !important;
  }

  .box1TopH {
    display: none !important;
  }

  .buttonsClassH {
    display: flex !important;
    flex-direction: column !important;
  }

  .btn1H {
    border-radius: 0px !important;
    margin-top: 5px !important;
  }

  .img2H {
    width: 42% !important;
    height: 43% !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1145px) {
  .img1 {
    width: 10% !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1021px) {
  .stack1 {
    width: 100% !important;
  }
  .stack22 {
    margin-left: 54px !important;
  }
  .greBox {
    padding-left: 26px !important;
    padding-right: 26px !important;
  }
}
