
@font-face {
  font-family: "Open Sans";
  src: url("../../Assets/fonts/OpenSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.sub-container-1 {
  padding-bottom: 2cm;

}

.background-image-container-1 {
    margin:2%;
    border-radius: 5px;
    background-image: url('../../Assets/Rectangle\ 6.svg');
    background-size: "cover";
    background-repeat: "no-repeat";
    background-position: "center";

}

.welcome-text2 {
  color: #FFF;
  font-family: 'ITC Charter Com';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.35px;
  position: relative;
  height: 40px;
  top: 40px;
  width: 200px;
  /* margin-top: 20px; */
  margin-left: 3%;
}

.dashboard-text-1 {
  color: #FFF;
  font-family: ITC Charter Com;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.32px;
  margin-top: 25px;
  margin-left: 3%;
  width: 450px;
}

.random-text {
  color: #FFF;
  font-family: Helvetica Neue LT Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.08px;
  margin-top: 20px;
  margin-left: 3%;
}

.search-menu {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 40px;
  margin-left: 2%;
  width: 436px;
  height: 37px;
  border-radius: 5px;

}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 436px;
  height: 40px;
  border-radius: 5px;
  margin-left: 3%;
  background: #FFF;
}

.search-icon-container {
  margin-right: 10px;
  position: relative;
}

/* .search-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-left: 0.6cm;
} */

.search-input-container {
  border-radius: 5px;
  background: #FFF;
  width: 436px;
}

.search-field {
  outline: none;
  width: 100%;
  height: 37px;
  color: #878787;
  font-family: 'Helvetica Neue LT Pro';
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  background: transparent;
  font-style: normal;
  font-weight: 400;
}

.meow{
width: 312px;
height: 172px;
flex-shrink: 0;
border-radius: 8px;
opacity: 0.9;
background: rgba(29, 43, 30, 0.80);
}

.rectangle-container {
    margin-left: 0%;
    margin-top:1%;
    margin-right:1%;
    background-color: #FFF;
    border-radius: 0.2%;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    position:relative;
    align-items: center;
    margin-bottom: 2%;
    overflow-x: hidden;
    border-radius: 6px;
  }

.rectangle-text {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 180px;
  height: 27px;
  position: relative;
  margin-left: 5%;
  top: 1.5%;
}

.line-below-1 {
  width: 100%;
  border: 1px solid #D0D5DD80;
  transform: rotate(-0deg); 
}


.rectangle-text-1 {
  display: flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 34px;
  position: relative;
  margin-left: 5%;
  margin-top: 15px;
  padding: 10px;
}

.rectangle-text-2 {
  color: #959595;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 83px;
  height: 27px;
  position: relative;
}


/* ************************** */

.table-container{
  overflow-x: auto;
  max-width: 100%;
}

table{
  width: 100%;
  /* border-collapse: collapse; */
}

.thead1 {
  background-color: #EAECF0 !important;
  padding: 0px;
  text-align: center;
  font-size: 12px;
  font-family:Poppins;
}

.tdata1{
  padding: 0px 0px;
  text-align: center;
  border: solid 1px #E7E7E7;
  font-size: 14px;
  font-family:Poppins;
}

.thead1.sticky-first-column,
.tdata1.sticky-first-column {
  position: sticky;
  /* left: 0; */
  background-color: #FFF;
  z-index: 3;
  width: 40px ;
}

.thead1.sticky-second-column,
.tdata1.sticky-second-column {
  position: sticky;
  left: 0px;
  background-color: #FFF;
  z-index: 2;
}

.thead1.fixed-last-column,
.tdata1.fixed-last-column {
  position: sticky;
  right: 0;
  background-color: #FFF;
  z-index: 2;
  width: 70px ;
  height:auto;
}

.thead1.fixed-width,
.tdata1.fixed-width {
  width: 220px ;
}


/* .mission10000-search-field{
    width: 259px;
    height: 31px;
    padding: 6px;
    border-radius: 6px;
    background-color: #F5F5F5;
    border: none;
    margin: auto;
} */

.tdata1 input{
    border: none;
    padding: 0;
    margin: 0;
    width: 55px;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    background-color: transparent;

    appearance: textfield;
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button{
        margin: 0;
    }
}

.pagination-container {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: #FFF;
  margin-top: -1.5%;
  margin-left: 80%;
}

.page-button {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
  cursor: pointer;
  background: #FFF;
}

.page-button.active {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #141212;
  color: #FFF; /* Text color when active */
}

.download {
  background: #000000;
  color: #ffffff;
  height: 41px;
  border-radius: 5px;
  margin: 5px;
  padding: 0px 10px;
  cursor: pointer;
  transition: box-shadow 0.3s, background 0.3s, color 0.3s;   
}

.download:hover {
  background: #5f5c5c;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
}



.dropdown-m10k {
  width: 94px;
  height: 38px;
  top: 20px;
  left: 1263px;
  padding: 9px 11px 9px 13px;
  border-radius: 7.69px;
  border: 0.96px solid #D0D5DD;
  cursor: pointer;
}


.dropdown {
  display: flex;
  border-radius: 5px;
  border: 1px dashed rgba(0, 0, 0, 0.46);
  width: 335px;
  height: 45px;
  flex-shrink: 0;
  margin-top: -6px;
  margin-left: 70%;
}

.dropdown option {
  border-radius: 5px;
  background: #DCDCDC;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  width: 146px;
  height: 79px;
}

