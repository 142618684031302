.stackCont {
  background-color: #eef4ed;
  border-radius: 15px;
}

@media only screen and (max-width: 1275px) {
  .stackCont {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .widthCheck{
    width: 1000px;
  }

  .stackCont::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  .stackCont::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }

  .stackCont::-webkit-scrollbar-track {
    background-color: #eef4ed;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
}
