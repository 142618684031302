.App-header1 {
    /* background-color: #282c34; */
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.options-container {
    display: flex;
    margin-left: auto;
    width: 457px;
    height: 44px;
    flex-shrink: 0;
}

.option {
    margin-right: 23px;
    display: flex;
    align-items: center;
    position: relative;
}

.background-image {
    /* position: absolute; */
    top: 0;
    left: 0;
}

.profile-image {
    color: #000;
    position: absolute;
    font-family: Helvetica Neue LT Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70px;
    height: 39px;
    flex-shrink: 0;
    margin-left: -0.29cm;
}

.settings-image,
.logout-image {
    color: #000;
    font-family: Helvetica Neue LT Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 60px;
    height: 23px;
    flex-shrink: 0;
}

.option-label {
    color: black;
    font-size: 16px;
    margin-left: 0.2cm;
}

.sub-container {
    padding-bottom: 2cm;

}

.background-image-container {
    margin: 32px;
    border-radius: 5px;
    background-image: url('../../Assets/Rectangle\ 6.svg');
}

.welcome-text1 {
    color: #FFF;
    font-family: 'ITC Charter Com';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.35px;
    position: relative;
    height: 40px;
    top: 40px;
    width: 219px;
    /* margin-top: 20px; */
    margin-left: 3%;
}

.dashboard-text {
    color: #FFF;
    font-family: ITC Charter Com;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.32px;
    margin-top: 25px;
    margin-left: 3%;
}

.random-text {
    color: #FFF;
    font-family: Helvetica Neue LT Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.08px;
    margin-top: 20px;
    margin-left: 3%;
}

.search-menu {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 40px;
    margin-left: 2%;
    width: 436px;
    height: 37px;
    border-radius: 5px;

}

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 436px;
    height: 40px;
    border-radius: 5px;
    margin-left: 3%;
    background: #FFF;
}

.search-icon-container {
    margin-right: 10px;
    position: relative;
}

.search-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-left: 0.6cm;
}

.search-input-container {
    border-radius: 5px;
    background: #FFF;
    width: 436px;
}

.search-field {
    outline: none;
    width: 100%;
    height: 37px;
    color: #878787;
    font-family: 'Helvetica Neue LT Pro';
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    background: transparent;
    font-style: normal;
    font-weight: 400;
}

.additional-options {
    margin-left: 11.5cm;
    display: flex;
    align-items: center;
}

.button {
    margin-right: 20px;
    width: 126px;
    height: 37px;
    background: #000;
    color: #FFF;
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.select-text {
    color: #FFF;
    width: 68px;
    height: 16px;
    flex-shrink: 0;
    font-family: Helvetica Neue LT Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 25px;
}

.options-below-container {
    display: flex;
    justify-content: center;
    /* Adjust the top margin as needed */
}

.option-below {
    text-align: center;
    margin-top: -10px;
    width: 160px;
    height: 26px;
    flex-shrink: 0;
    color: #818181;
    text-align: center;
    font-family: Helvetica Neue LT Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.rotate {
    transform: rotate(180deg);
  }

.option-below.selected {
    width: 136px;
height: 26px;
flex-shrink: 0;
color: #000;
text-align: center;
font-family: Helvetica Neue LT Pro;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-bottom: 5px solid #205B11;
  }

  .dropdown-options {
      position: absolute;
      left: 25.12cm;
      top: calc(100% + -155px);
      width: 110px;
      padding: 8px;
      z-index: 1;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.94);
      box-shadow: -3px 5px 15px 0px rgba(0, 0, 0, 0.35);
  }
  
  .dropdown-options div {
    cursor: pointer;
    text-align: center;
        padding: 8px;
        color: #FFF;
        font-family: Helvetica Neue LT Pro;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: background-color 0.3s ease;
  }
  
  .dropdown-options div:hover {
    background: #474644;
  }
