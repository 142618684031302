.wrap3-counter {
  position: relative;
  text-align: center;
  margin-top: 20%;
}

.wrap3 {
  position: relative;
  display: inline-block;
  perspective: 150px;
  width: 50px;
  height: 100px;
  margin: 0 2px;
  
  
}

.wrap3 .wrap3-num {
  width: 60px;
  height: 41px;
  text-align: center;
  position: absolute;
  overflow: hidden;
  border: 1px solid #B9D1BB; 
  
}

.wrap3 .wrap3-num.top {
  top: 0;
  background: #fff; /* White background */
  border-radius: 0px 0px 0 0;
  /* box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.9); */
  border-bottom: 1px solid #7B6C6C;
  border: 2px solid #B9D1BB;
}

.wrap3 .wrap3-num.top:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: -4px;
  width: 2px;
  height: 8px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
}

.wrap3 .wrap3-num.top:after {
  content: '';
  position: absolute;
  right: 0px;
  bottom: -4px;
  width: 2px;
  height: 8px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
}

.wrap3 .wrap3-num.top .number {
  top: 100%;
  transform: translateY(-50%);
  color: #000; 
}

.wrap3 .wrap3-num.bottom {
  top: 40px;
  height: 50px;
  background: #fff; /* White background */
  border-radius: 0 0 0px 0px;
  /* box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.9); */
  border-top: 1px solid #7B6C6C !important;
  border-bottom: 2px solid #B9D1BB !important;
}

.wrap3 .wrap3-num.bottom:before {
  content: '';
  position: absolute;
  left: 0px;
  top: -4px;
  width: 2.5px;
  height: 14px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
  z-index: 1; 
}


.wrap3 .wrap3-num.bottom:after {
  content: '';
  position: absolute;
  right: 0px;
  top: -4px;
  width: 3px;
  height: 14px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
  z-index: 1; 
}

.wrap3 .wrap3-num.bottom .number {
  bottom: 100%;
  transform: translateY(50%);
  color: #000; /* Black color for numbers */
  border: 2px solid #B9D1BB;
}

.wrap3 .wrap3-num.flip {
  opacity: 1;
  background: #fff; /* White background */
  z-index: 1;
  border-radius: 0px 0px 0 0;
  border-bottom: 1px solid #7B6C6C;
  transform-origin: 50% 100%;
  
}

.wrap3 .wrap3-num.flip:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: -4px;
  width: 2.5px;
  height: 14px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
  z-index: 1;
}

.wrap3 .wrap3-num.flip:after {
  content: '';
  position: absolute;
  right: 0px;
  bottom: -4px;
  width: 3px;
  height: 14px;
  background: #dedede;
  border-radius: 0px;
  background: linear-gradient(180deg, #032E06 0%, #52843B 100%);
  z-index: 1; 
}

.wrap3 .wrap3-num.flip.in {
  top: 0;
}

.wrap3 .wrap3-num.flip.in .number {
  top: 100%;
  transform: translateY(-50%);
  color: #000; /* Black color for numbers */
  border: 2px solid #B9D1BB; /* Thin green outline */
}

.wrap3 .wrap3-num.flip.out {
  top: -1px;
}

.wrap3 .wrap3-num.flip.out .number {
  top: calc(100% - 1px);
  transform: translateY(-50%) scaleY(-1);
  color: #000; /* Black color for numbers */
  
}

.wrap3 .wrap3-num .number {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 55px;
  width: 100%;
  font-weight: bold;
  
}

@media (max-width: 768px) {
  .wrap3 {
      width: 30px; /* Adjusted for mobile */
      height: 100px;
  }

  .wrap3 .wrap3-num {
      width: 42px; 
      height: 35px; 
  }

  .wrap3 .wrap3-num.top, .wrap3 .wrap3-num.bottom, .wrap3 .wrap3-num.flip {
    
  }

  .wrap3 .wrap3-num.bottom {
      top: 35px;
      height: 40px;
  }

  .wrap3 .wrap3-num .number {
      font-size: 55px; 
  }

}

@media (max-width: 768px) {
  .wrap3 .wrap3-num .number {
      font-size: 43px; 
  }
}


