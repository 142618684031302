.donutChartCont{
    width: 50%;
    background-color: rgba(238, 244, 237, 1);
    position: relative;
    border-radius: 15px;
}

.checkWidth{
    width: 50%;
}

@media only screen and (max-width: 900px) {
    .donutChartCont{
        width: 100% !important;
    }
    .checkWidth{
        width: 100% !important;
    }
}