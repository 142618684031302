.horizontal-list {
    list-style-type: none;
    margin-top: 0%;
    justify-content: center;
    display: flex;
    padding: 15px;
    align-items: center;
    background-color: rgb(255, 255, 255);
    position: relative; 
  }
  
  .horizontal-list:before,
  .horizontal-list:after {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 20px; 
  }

  
  .horizontal-list li {
    margin-right: 30px; 
  }
  
  .horizontal-list li a {
    text-decoration: none;
    color: #818181;
    transition: color 0.3s;
  }
  
  .horizontal-list li a:hover {
    color: rgb(53, 53, 53);
    font-weight: bold;
  }
  
  .horizontal-list li a:focus {
    color: rgb(5, 5, 5);
    font-weight: bolder;
  }

  


  
  
  
  
  