
  
  @media only screen and (max-width: 1275px) {
    .stackContBI {
      overflow-y: hidden;
      overflow-x: auto;
    }
  
    .stackContBI::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }
  
    .stackContBI::-webkit-scrollbar-thumb {
      background-color: #555;
      border-radius: 10px;
    }
  
    .stackContBI::-webkit-scrollbar-track {
      background-color: #eef4ed;
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    }
  }
  