.flipping-box {
    width: 58px;
    height: 86px;
    border-radius: 1px;
    background: #FFF; /* Slightly darker shade of white */
    border: 2px solid #B9D1BB; /* Thin green outline */
    position: relative; /* Relative positioning to place the line and value properly */
    perspective: 1000px; /* Define the perspective for 3D effect */
    transition: transform 0.5s ease; /* Define animation duration and easing */
  
    @media (max-width: 600px) {
      /* For extra small (xs) and small (sm) screens */
      width: 48px;
      height: 72px;
      border-radius: 0.5px;
    }
  
    @media (min-width: 960px) {
      /* For medium (md), large (lg), and extra large (xl) screens */
      width: 58px;
      height: 86px;
      border-radius: 1px;
    }
  }
  
  .flipping-box.flip {
    transform: rotateX(180deg); /* Flip the box on X-axis */
  }
  
  .horizontal-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0.5px;
    background-color: #7b6c6c; /* Dark green color */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); /* Shadow under the line */
  
    @media (max-width: 600px) {
      /* For extra small (xs) and small (sm) screens */
      height: 0.3px;
    }
  
    @media (min-width: 960px) {
      /* For medium (md), large (lg), and extra large (xl) screens */
      height: 0.7px;
    }
  }
  
  .static-box {
    width: 58px;
    height: 86px;
    border-radius: 1px;
    background: #FFF; /* Slightly darker shade of white */
    border: 2px solid #B9D1BB; /* Thin green outline */
    position: absolute; /* Relative positioning to place the line and value properly */
    margin-top: 5px; /* Adjust as needed for spacing between boxes */
    transition: transform 0.5s ease; /* Define animation duration and easing */
  }
  
  /* Additional styles for responsive design */
  @media (max-width: 600px) {
    /* For extra small (xs) and small (sm) screens */
    .static-box {
      width: 48px;
      height: 72px;
      border-radius: 0.5px;
    }
  }
  
  @media (min-width: 960px) {
    /* For medium (md), large (lg), and extra large (xl) screens */
    .static-box {
      width: 58px;
      height: 86px;
      border-radius: 1px;
    }
  }