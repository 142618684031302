.checkSD::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  .checkSD::-webkit-scrollbar-thumb {
    background-color: #054B0C3D;
    border-radius: 10px;
  }

  .checkSD::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 10px;
    box-shadow: transparent;
  }