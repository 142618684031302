.dct-target{
    background-color: #FCE5CD !important;
}

.dct-target1{
    background-color: #FFFF00 !important;
}

.dct-target2{
    background-color: #FEFCBC !important;
}

.pre-mig1{
    background-color: #9BC2E6 !important;
}

.pre-mig2{
    background-color: #DFECF7 !important;
}

.erp-trial1{
    background-color: #F4B084 !important;
}

.erp-trial2{
    background-color: #FCE5CD !important;
}

.go-live1{
    background-color: #92D050 !important;
}

.go-live2{
    background-color: #C6D6C1  !important;
}

.hardware-status1{
    background-color: #FFCC00 !important;
}

.hardware-status2{
    background-color: #FFF2CC !important;
}


.total-col-color{
    background-color: #D9EAD3 !important;
}

.tdata2{
    border: solid 2px #E7E7E7
}