.react-calendar__navigation button:nth-child(1),
.react-calendar__navigation button:nth-last-child(1) {
    display: none;
}

.react-calendar__navigation button:nth-child(3) {
    flex: auto !important;
    font-size: 14px !important;
    font-family: Poppins !important;
    background-color: white !important;
    border-radius: 6px;
    font-weight: 700 !important;
    pointer-events: none !important;
}

.react-calendar__navigation button:nth-child(2),
.react-calendar__navigation button:nth-last-child(2) {
    font-size: 20px !important;
    font-weight: 700 !important;
}

.custom-calendar-container {
    position: relative;
    width: fit-content;
}

.month-year-button {
    padding: 8px 0px 8px 0px;
    width: 50px;
    text-transform: capitalize;
    background-color: white !important;
    color: black !important;
    border-radius: 6px;
    font-weight: 700 !important;
    margin-right: 3px !important;
    box-shadow: none !important;
    cursor: context-menu !important;
    font-family: Poppins;
}

.filled-date {
    background-color: #065036 !important;
    color: white;
}

.custom-tile-content {
    position: relative;
}

.marked-image {
    position: absolute;
    top: -31px;
    right: -10px;
    width: 22px
}

.date.filled {
    color: blue;
    /* Example styling for filled dates */
}

@media screen and (max-width:360px) {
    .marked-image {
        position: absolute;
        top: -30px;
        right: -8px;
        width: 100%;
    }
}

@media screen and (max-width: 606px) and (min-width: 400px) {
    .marked-image {
        width: 17px;
    }
}

@media only screen and (width: 1024px) {
    .marked-image {
        width: 20px;
    }
    .image-P{
        margin-top: 164%
    }
    .form-p{
        padding-left: 0px;
    }
    .mark-P{
        margin-left: 0px !important;
    }
}
@media only screen and (width: 912px) {
    .marked-image {
        width: 20px;
    }
    .image-P{
        margin-top: 164%
    }
    .form-p{
        padding-left: 0px !important;
    }
    .mark-P{
        margin-left: 0px !important;
    }
}
@media only screen and (width: 375px) {
    .marked-image {
        width: 10px;
    }
    .form-p{
        padding-left: 0px !important;
    }
    .mark-P{
        margin-left: 0px !important;
    }
}