.sub-container {
    padding-bottom: 2cm;

}

.sub-sub-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mission-10k-text {
    width: 50%;
    display: flex;
}

.searchbar-div {
    position: relative;
}

.div-span {
    margin-left: 10px;
    margin-right: 10px;
}

.background-image-container {
    margin: 32px;
    border-radius: 5px;
    background-image: url('../../Assets/Rectangle6_2.svg');
}

.welcome-text1 {
    color: #FFF;
    font-family: 'ITC Charter Com';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.35px;
    position: relative;
    height: 40px;
    top: 40px;
    width: 219px;
    /* margin-top: 20px; */
    margin-left: 3%;
}

.dashboard-text {
    color: #FFF;
    font-family: ITC Charter Com;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.32px;
    margin-top: 25px;
    margin-left: 3%;
}

.random-text {
    color: #FFF;
    font-family: Helvetica Neue LT Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.08px;
    margin-top: 20px;
    margin-left: 3%;
}

.search-menu {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 40px;
    margin-left: 2%;
    width: 436px;
    height: 37px;
    border-radius: 5px;

}

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 436px;
    height: 40px;
    border-radius: 5px;
    margin-left: 3%;
    background: #FFF;
}

.search-icon-container {
    margin-right: 10px;
    position: relative;
}

.search-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-left: 0.6cm;
    position: absolute;
    top: 40%;
    right: 2%;
    transform: translateY(-50%);
    cursor: pointer,
}

@media (max-width: 1227px) {
    .search-icon {
      display: none;
    }
  }

.search-input-container {
    border-radius: 5px;
    background: #FFF;
    width: 436px;
}

.search-field {
    outline: none;
    width: 100%;
    height: 37px;
    color: #878787;
    font-family: 'Helvetica Neue LT Pro';
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    background: transparent;
    font-style: normal;
    font-weight: 400;
}


/* ************************** */

.table-container {
    padding-top: 1%;
    margin-left: 0%;
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 2%;
}

table {
    width: 100%;
    /* border-collapse: collapse; */
}

.thead2 {
    background-color: #EAECF0 !important;
    padding: 0px;
    text-align: center;
    font-size: 8px;
    font-family:Poppins;
}

.tdata2{
    padding: 0px 0px;
    text-align: center;
    border: solid 1px #E7E7E7;
    font-size: 10px;
    font-family:Poppins;
}

.thead2.sticky-first-column,
.tdata2.sticky-first-column {
    position: sticky;
    /* left: 0; */
    background-color: #FFF;
    z-index: 3;
    width: 40px ;
}

.thead2.sticky-second-column,
.tdata2.sticky-second-column {
    position: sticky;
    left: 0px;
    background-color: #FFF;
    z-index: 2;
    width: 220px !important;
}

.thead2.fixed-last-column,
.tdata2.fixed-last-column {
    position: sticky;
    right: 0;
    background-color: #FFF;
    z-index: 2;
    width: 70px ;
    height:auto;
}

.thead2.fixed-width,
.tdata2.fixed-width {
    width: 220px ;
}

.mission10000-search-field {
    width: 100%;
    height: 31px;
    padding: 6px;
    border-radius: 6px;
    background-color: #F5F5F5;
    border: none;
    margin: auto;
    margin-top: 8%;
    margin-left: 2%;
}

.tdata2 input {
    border: none;
    padding: 0;
    margin: 0;
    width: 45px;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 10px;
    font-family:Poppins;
    appearance: textfield;
    -moz-appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        margin: 0;
    }
}

/* Add this to your existing CSS or create a new CSS file */

.rectangular-container1 {
    margin-left: 0%;
    margin-top:1%;
    margin-right:1%;
    background-color: #FFF;
    border-radius: 0.2%;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    position:relative;
    align-items: center;
    margin-bottom: 2%;
    overflow-x: hidden;
    border-radius: 6px;
  }


.page-button {
    padding: 8px;
    gap: 10px;
    border: 1px solid #B3B3B3;
    border-radius: 6px;
    cursor: pointer;
    background: #FFF;
    font-size: 10px;
    font-family:Poppins;
}

.page-button.active {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px !important;
    border: 1px solid #00261C !important;
    background: #0b4737 !important;
    color: #FFF;
    /* Text color when active */
}

.download {
    background: #000000;
    color: #ffffff;
    height: 41px;
    border-radius: 5px;
    margin: 5px;
    padding: 0px 10px;
    cursor: pointer;
    transition: box-shadow 0.3s, background 0.3s, color 0.3s;
}

.download:hover {
    background: #5f5c5c;
    color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.dropdown-div {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: space-between;
}



.dropdown-m10k {
    width: 65px;
    height: 32px;
    padding: 0px 0px 0px 10px; 
    border-radius: 7.69px;
    border: 0.96px solid #D0D5DD;
    cursor: pointer;
    gap: 7.69px; 
}

.dropdown-m10k-excel {
    width: 94px;
    height: 32px;
    padding: 9px 11px 9px 13px; 
    border-radius: 7.69px;
    border: 0.96px solid #D0D5DD;
    cursor: pointer;
    gap: 7.69px; 
}

/* Pratikshaaaa */
@media screen and (max-width:390px) {
    .background-image-container {
        width: 150vw;
    }

    .sub-container {
        width: 150vw;
    }

    .sub-sub-container {
        flex-direction: column;
    }

    .mission-10k-text {
        width: 100%;
        display: flex;
    }

    .searchbar-div {
        margin-left: 1.5cm;
        margin-top: -32px;
        position: relative;
    }

    .mission10000-search-field {
        width: 50vw;
        margin-top: 24%;
    }

    .search-icon {
        margin-top: 8%;
        margin-right: 5px;
        position: absolute;
    }

    .dropdown-div {
        width: 100%;
    }

    .show-rows-select-dropdown-m10k {
        margin-top: 6px;
    }

    .dropdown-m10k {
        width: 20vw;
    }

    .dropdown-m10k-excel {
        width: 20vw;
    }

}

@media screen and (max-width: 768px) and (min-width: 412px) {
    .background-image-container {
        width: 142vw;
    }

    .sub-container {
        width: 142vw;
    }

    .sub-sub-container {
        flex-direction: column;
    }

    .mission-10k-text {
        width: 100%;
        display: flex;
    }

    .searchbar-div {
        margin-left: 1.5cm;
        margin-top: -32px;
        position: relative;
    }

    .mission10000-search-field {
        width: 50vw;
        margin-top: 24%;
    }

    .search-icon {
        margin-top: 8%;
        margin-right: 5px;
        position: absolute;
    }

    .dropdown-div {
        width: 100%;
    }

    .show-rows-select-dropdown-m10k {
        margin-top: 6px;
    }

    .dropdown-m10k {
        width: 20vw;
    }

    .dropdown-m10k-excel {
        width: 20vw;
    }

}

